import { AxiosError, AxiosResponse } from 'axios';
import { CalculationHistoryItemModel } from './CalculationHistoryItemService';
import { Model, Rest } from './Rest';
import api from './api';

export interface CalculationHistoryModel extends Model {
  ind_use_stock?: boolean;
  query_from?: string;
  query_to: string;
  customer_id: number;
  items: CalculationHistoryItemModel[];
  created_at: string;
  updated_at: string;
}
interface ExportedCalculationHistoryItemModel {
  structure_name: string,
  name: string,
  type: string,
  quantity: number,
  sold_qty: number,
  calculated_quantity: number,
  is_from_structure: true
}

export class CalculationHistoryService extends Rest {
  resourceable_as = 'App\\Models\\CalculationHistory';

  getDefaultModel(merge?: Record<string, any>): CalculationHistoryModel {
    return {
      query_to: '',
      customer_id: 0,
      updated_at: '',
      created_at: '',
      items: [],
      ...merge,
    };
  }

  exportDetailed(calculationHistoryId: number | string) : Promise<AxiosResponse<ExportedCalculationHistoryItemModel[]>> {
    return new Promise((resolve, reject) => {
      api
        .get(`${this.resoruceRoute}/${calculationHistoryId}/export-detailed`)
        .then((
          response: AxiosResponse<ExportedCalculationHistoryItemModel[]>,
        ) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
}

export default new CalculationHistoryService('CalculationHistoryService', '/calculations-history');
