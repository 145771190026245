import { Icon } from 'components/Icon';
import { PageTitleRow } from 'components/Layout/PageTitleRow';
import { Slugify } from 'helpers/Slugfy';
import { useView } from 'hooks/useView';
import { Button, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'routing/routes';
import { CalculationHistoryItemModel } from 'services/CalculationHistoryItemService';
import CalculationHistoryService, { CalculationHistoryModel } from 'services/CalculationHistoryService';
import NotificationService from 'services/NotificationService';
import { OmieService } from 'services/OmieService';

export function CalculationHistoryView() {
  const params = useParams<{id: string}>();
  const { model } = useView<CalculationHistoryModel>(CalculationHistoryService, params.id || '');

  function formatCell(value: number) : string | number {
    return value !== 0 ? value : '';
  }

  async function onDownlodCsv() {
    if (!params.id || !model) {
      return;
    }
    const response = await CalculationHistoryService.exportDetailed(params.id);
    if (!response.data.length) {
      NotificationService.warning('Nenhum produto encontrado');
      return;
    }
    const separator = ';';
    const header = [
      '"Produto"',
      '"Insumo"',
      '"Qtde Malha"',
      '"Qtde PV"',
      '"Qtde Malha * Qtde PV"',
    ];
    const csv = [
      header.join(separator),
    ];
    response.data.forEach((row) => {
      if (!row.is_from_structure || row.sold_qty === 0) {
        return;
      }
      csv.push(
        [
          `"${row.structure_name}"`,
          `"${row.name}"`,
          `"${row.quantity}"`,
          `"${row.sold_qty}"`,
          `"${row.calculated_quantity}"`,
          // `"${formatCell(row.saldo)}"`,
        ].join(separator),
      );
    });

    const csvContent = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv.join('\r\n'))}`;
    const createdAt = new Date(model.created_at).toLocaleDateString('pt-BR', {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const link = document.createElement('a');
    link.id = 'lnkDwnldLnk';
    document.body.appendChild(link);
    link.download = `historico-calculo-pcp-smart-${Slugify(createdAt)}.csv`;
    link.href = csvContent;
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="layout-wrapper layout-wrapper-list">
      <PageTitleRow title="Resultado do cálculo">
        <div className="d-flex gap-4">
          <Button variant="outline-primary" type="button" onClick={ onDownlodCsv }>
            <Icon name="download" className="fs-5 align-middle" />
            {' '}
            Baixar CSV
          </Button>
          <Link className="btn btn-primary" to={ routes.omieCalculationHistory.path }>
            <Icon name="keyboard_arrow_left" className="fs-5 align-middle" />
            Voltar
          </Link>
        </div>
      </PageTitleRow>

      <div className="mb-2 mt-3">
        <Table className="border-top" striped hover responsive="md">
          <thead>
            <tr>
              <th>Código</th>
              <th>Produto</th>
              <th>Tipo</th>
              <th><span className="text-nowrap">Saldo inicial</span></th>
              <th><span className="text-nowrap">Produzindo</span></th>
              <th><span className="text-nowrap">Comprando</span></th>
              <th><span className="text-nowrap">Pedido/venda</span></th>
              <th><span className="text-nowrap">Insumo</span></th>
              <th><span className="text-nowrap">Estoque min.</span></th>
              <th><span className="text-nowrap">A produzir</span></th>
              <th><span className="text-nowrap">A comprar</span></th>
              <th><span className="text-nowrap">Saldo</span></th>
            </tr>
          </thead>
          <tbody className="border-top">
            {model ? model.items.map((item: CalculationHistoryItemModel, i: number) => {
              const isProduction = OmieService.isProduction(item.vendor_type);

              return (
                <tr key={ item.vendor_id }>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{OmieService.PRODUCT_TYPE_MAP[item.vendor_type].name}</td>
                  <td>{formatCell(item.stock)}</td>
                  <td>{formatCell(item.in_production_qty)}</td>
                  <td>{formatCell(item.in_order_request_qty)}</td>
                  <td>{formatCell(item.sold_qty)}</td>
                  <td>{formatCell(item.sold_as_feedstock_qty)}</td>
                  <td>{formatCell(item.minimum_stock)}</td>
                  <td>{formatCell(isProduction ? item.to_buy_or_produce : 0)}</td>
                  <td>{formatCell(!isProduction ? item.to_buy_or_produce : 0)}</td>
                  <td>{formatCell(item.balance)}</td>
                </tr>
              );
            }) : null}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
